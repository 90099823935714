var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"6","md":"6","lg":"6"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" Transfer ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please Enter Number To Transfer ")]),_c('validation-observer',{ref:"TransferForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.transfer($event)}}},[_c('b-form-group',{attrs:{"label":"Mobile No","label-for":"mobile-no"}},[_c('validation-provider',{attrs:{"name":"Transfer Number","vid":"mobile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.userOptions,"placeholder":"Select Mobile No","reduce":function (val) { return val.value; }},model:{value:(_vm.transfer_num),callback:function ($$v) {_vm.transfer_num=$$v},expression:"transfer_num"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Amount","label-for":"amount-no"}},[_c('validation-provider',{attrs:{"name":"amount","vid":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount","state":errors.length > 0 ? false:null,"name":"amount","placeholder":"Enter Amount"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Note","label-for":"note"}},[_c('validation-provider',{attrs:{"name":"note","vid":"note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"3"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loading}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"small":""}}),_vm._v(" Transfer ")],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }