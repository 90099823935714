<template>
    <b-row>
        <b-col cols="12">
            <b-card>
            	 <b-row>
               <b-col
          sm="6"
          md="6"
          lg="6"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Transfer
          </b-card-title>
          <b-card-text class="mb-2">
            Please Enter Number To Transfer
          </b-card-text>
          <!-- form -->
          <validation-observer
            ref="TransferForm"
            #default="{invalid}"
          >
            <b-form
              @submit.prevent="transfer"
            >
              <!-- email -->
              <b-form-group
                label="Mobile No"
                label-for="mobile-no"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Transfer Number"
                  vid="mobile"
                  rules="required"
                >
                 <v-select
                    v-model="transfer_num"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    class="w-100"
                    placeholder="Select Mobile No"
                    :reduce="val => val.value"
                  />
                 <!--  <b-form-input
                    id="transfer_num"
                    v-model="transfer_num"
                    :state="errors.length > 0 ? false:null"
                    name="transfer_num"
                    placeholder="Enter Transfer Number"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group
                label="Amount"
                label-for="amount-no"
              >
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  vid="amount"
                  rules="required"
                >
                  <b-form-input
                    id="amount"
                    v-model="amount"
                    :state="errors.length > 0 ? false:null"
                    name="amount"
                    placeholder="Enter Amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group
                label="Note"
                label-for="note"
              >
                <validation-provider
                  #default="{ errors }"
                  name="note"
                  vid="note"
                  rules="required"
                >
                   <b-form-textarea
				      id="textarea-default"
				      placeholder="Textarea"
				      v-model="note"
				      rows="3"
				    />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || loading"
              >
                <b-spinner
                  v-show="loading"
                  small
                />
                Transfer
              </b-button>
            </b-form>
          </validation-observer>
         </b-col>
         <!-- <b-col
          sm="6"
          md="6"
          lg="6"
          class="px-xl-2 mt-5"
        >
        <p>Form: <span>{{currentUser.loginid}}</span> </p>
         <p v-if="mobile">To: <span  >{{mobile}}</span> </p>
          <p v-if="amount">Amount: <span  >{{amount}}</span> </p>
          <p v-if="notes">Note: <span  >{{notes}}</span> </p>
       </b-col> -->
        </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import { 
  BRow,
  BForm,
  BCol,	
  BTabs, 
  BTab, 
  BCard, 
  BCardText,
  BFormGroup,
  BCardTitle,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BButton,
  BSpinner,
  BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserService from '../../services/user.service'
import RechargeService from '../../services/recharge.service'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'



export default {
    components: {
    	BRow,
	    BCol,	
      BCard,
      BCardText,
      BRow,
      BCol,
      vSelect,
	    BFormGroup,
	    BFormInput,
	    BCardTitle,
	    BInputGroupAppend,
	    BInputGroup,
	    BForm,
	    BButton,
	    BSpinner,
	    BFormTextarea,
	    ValidationProvider,
	    ValidationObserver,
    },
    data() {
        return {
            loading: false,
            amount:'',
            userOptions:[],
            transfer_num:'',
            note:'',
            required,
            mainProps: {
                width: 100,
                height: 100,
                class: 'm1',
            },
        }
    },
    computed: {
        ...mapGetters([
            'currentUser',
        ]),
    },
    mounted() {
        this.getDownlineUserList()
    },
    methods: {
       getDownlineUserList(data) {
        this.showLoading = true
        UserService.getDownlineUsers(data).then(
          response => {
             const self = this
            response.DownlineUsers.map((value, key) => {
              self.userOptions.push(
                { value: value.mobile, label: value.mobile+' ('+value.name+')' },
              )
            })
          },
          error => {
            this.showLoading = false
          },
        )
      },
        transfer() {
            this.loading = true
            RechargeService.transfer({'transfer_num':this.transfer_num,'amount':this.amount,'note':this.note}).then(
                response => {
                    this.loading = false
                    this.$toast({
			            component: ToastificationContent,
			            props: {
			              title: 'Transfer',
			              text: response.message,
			              icon: 'SendIcon',
			              variant: (response.code==200)?'success':'danger',
			            },
			          })
                },
                error => {
                    this.loading = false
                     this.$toast({
			            component: ToastificationContent,
			            props: {
			              title: 'Transfer',
			              text: error.message,
			              icon: 'SendIcon',
			              variant: 'error',
			            },
			          })
                },
            )
        },
    },
};

</script>
