import ApiService from '../common/api.service'
import StorageService from '../common/storage.service'
import decrypt from '../common/decrypt'

class RechargeService {
  getOperatorByMainType() {
    return ApiService.get('all/operator/by/main-type').then(response => decrypt(response.data.data), error => Promise.reject(error))
  }

  transfer(data){
  	return ApiService.post('transfer',data).then(response =>response.data, error => Promise.reject(error))
  }
}

export default new RechargeService()
